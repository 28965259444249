import vibeCover from "./img/releases/cover.jpg";

export const BANDS_IN_TOWN_APP_ID = "01d637208fbf5f3be242f58ab29268be"

export const LINKTREE_LINKS = [
    {
        label: "Website",
        url: "/"
    },
    {
        label: "Vibe on Spotify!",
        url: "https://open.spotify.com/track/3Cynv63p3EfKlcySoUp64r"
    },
    {
        label: "Vibe on Apple Music!",
        url: "https://music.apple.com/artist/1713019741"
    },
    {
        label: "Shows",
        url: "https://www.bandsintown.com/a/15505028-entropy-hill"
    },
    {
        label: "Instagram",
        url: "https://www.instagram.com/entropy_hill/"
    },
    {
        label: "Facebook",
        url: "https://www.facebook.com/entropyhillband/"
    },
    {
        label: "MX3",
        url: "https://mx3.ch/entropyhill/"
    },
]

export const MUSIC_ENTRIES = [
    {
        title: "Vibe",
        coverImage: vibeCover,
        links: {
            spotifyEmbedded: "https://open.spotify.com/embed/track/3Cynv63p3EfKlcySoUp64r",
            spotify: "https://open.spotify.com/track/3Cynv63p3EfKlcySoUp64r",
            apple: "https://music.apple.com/us/album/vibe-single/1713030118",
            soundcloud: "https://soundcloud.com/entropy-hill/vibe",
            deezer: "",
            tidal: ""
        }
    }
]

export const SOCIAL_MEDIA_ACCOUNTS = [
    {
        network: "",
        url: "https://www.instagram.com/entropy_hill",
    },
    {
        network: "",
        url: "https://www.facebook.com/entropyhillband/",
    },
    {
        network: "",
        url: "https://open.spotify.com/artist/4APfaKKTUYeoETKFP4HCTy",
    },
    {
        network: "itunes",
        url: "https://music.apple.com/artist/1713019741",
    },
    {
        network: "",
        url: "https://www.bandsintown.com/a/15505028-entropy-hill",
    },
    {
        network: "",
        url: "mailto:booking@entropyhill.com",
    },
]